import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import debounce from "lodash/debounce";

export default class Select2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      inputValue: "",
      options: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.calculateOptions = this.getOptions.bind(this);
    this.getNewSelectedValues = this.getNewSelectedValues.bind(this);

    this.debouncedOnSearch = props.debounceOnChange
      ? debounce(props.onSearch, 400)
      : props.onSearch;
  }
  styles() {
    return {
      control: (base, other) => {
        return {
          ...base,
          "&:hover *,&:hover .icon-disabled-client-panel .path1:before": {
            color: this.props.hoverColor || this.props.color,
          },
          "&:hover": {
            backgroundColor:
              this.props.hoverBackgroundColor || this.props.backgroundColor,
          },
          color: this.props.color,
          border: this.props.border,
          borderRadius: 4,
          backgroundColor: other.selectProps.isDisabled
            ? "#f2f2f2"
            : other.selectProps.value?.length
            ? this.props.selectedBackground
            : this.props.controlBackground,
          // This line disable the blue border
          boxSizing: "border-box",
          boxShadow: "none",
          minHeight: 40,
          position: "unset",
          transform: "unset",
          cursor: "pointer",
          width: this.props.width,
          height: !this.props.multi ? 40 : undefined,
          "& > div": {
            color: this.props.color,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: this.props.minHeight,
            height: !this.props.multi ? 40 : undefined,
            position: "unset",
            transform: "unset",
            display: "flex",
            alignItems: "center",
          },
          "&:focus-within, &:hover": {
            color: this.props.color,
            borderColor: "#66afe9",
          },
          "& *": {
            color: this.props.color,
          },
        };
      },
      input: (styles) => ({
        ...styles,
        color: this.props.color,
        height: !this.props.multi ? 40 : undefined,
        minHeight: this.props.minHeight,
        marginTop: 0,
        position: !this.props.multi ? "absolute" : "relative",
        display: "flex",
        alignItems: "center",
        width: `calc(100% - ${this.props.isClearable ? "60px" : "40px"})`,
      }),
      valueContainer: (styles) => ({
        ...styles,
        "input[readonly]": {
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        ...(!this.props.isSearchable
          ? {
              input: {
                position: "absolute !important",
              },
            }
          : {}),
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        paddingLeft: 0,
        width: this.props.dropDownSize,
      }),

      placeholder: (styles) => ({
        ...styles,
        color: this.props.color,
        height: !this.props.multi ? 40 : undefined,
        minHeight: this.props.minHeight,
        display: "flex",
        alignItems: "center",
        position: "unset",
        transform: "unset",
        paddingLeft: 4,
        fontWeight: this.props.fontWeight,
        margin: 0,
        "& *": {
          color: this.props.color,
        },
        "& .icon-disabled-client-panel .path1:before": {
          color: this.props.color,
        },
      }),
      singleValue: (styles) => ({
        ...styles,
        position: "unset",
        transform: "unset",
        paddingLeft: 4,
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: this.props.backgroundColor,
        color: this.props.color,
        borderRadius: 16,
        padding: "0px 12px",
      }),
      menuList: (styles) => ({
        ...styles,
        border: "none",
        boxShadow: "0 6px 12px 0 rgba(82, 97, 115, 0.5)",
      }),
      menu: (styles) => ({
        ...styles,
        width: "max-content",
        minWidth: "100%",
        ...(this.props.placement === "right" ? { right: 0 } : { left: 0 }),
      }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        cursor: "pointer",
        background: (() => {
          if (isSelected) return "#2a79d1 !important";
          if (isFocused && !isSelected) return "#f0f0f4";
          return "white";
        })(),
        color: isSelected ? "#fff" : "#153447",
        zIndex: 1,
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: this.props.color,
        fontSize: 14,
      }),
      ...this.props.styles,
    };
  }
  getNewSelectedValues(action, selected) {
    switch (action.action) {
      case "select-option":
        return this.props.multi
          ? [...this.state.selected, action.option]
          : selected;
      case "remove-value":
        return this.state.selected.filter(
          (v) => v.value !== action.removedValue.value
        );
      case "clear":
        return [];
      default:
        return this.state.selected;
    }
  }
  getNewOptions(selected) {
    return Object.keys(this.props.options)
      .map((key) => ({
        value: key,
        label: this.props.options[key],
      }))
      .filter(
        (option) => option.value !== selected?.value || option.value !== "new"
      );
  }

  onChange(newSelected, action) {
    const selected = this.getNewSelectedValues(action, newSelected);

    if (!this.props.multi && selected?.value === this.state?.selected?.value) {
      return;
    }

    const options = this.getNewOptions(selected);

    if (!this.props.isDropDownButton) {
      return this.setState(
        {
          ...this.state,
          selected,
          options,
        },
        () => {
          if (this.props.multi) {
            this.props.onChange(
              selected == null ? [] : selected.map((v) => v.value),
              action,
              this.state.inputValue
            );
            this.setState({ inputValue: "" });
          } else {
            this.setState({ inputValue: "" });
            this.props.onChange(selected?.value, action);
          }
        }
      );
    }
    return this.props.onChange(selected?.value, action);
  }

  onInputChange(inputValue) {
    this.setState({ inputValue });
    this.debouncedOnSearch(inputValue);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const options = this.getOptions(nextProps);
    let optionsChanged, valueChanged;

    try {
      optionsChanged =
        JSON.stringify(Object.keys(nextProps.options)) !==
          JSON.stringify(Object.keys(this.props.options)) ||
        JSON.stringify(Object.values(nextProps.options)) !==
          JSON.stringify(Object.values(this.props.options));
      valueChanged =
        JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value) ||
        JSON.stringify(nextProps.value) !== this.state.selected?.value ||
        "";
    } catch (e) {
      console.error(e);
    }

    if (optionsChanged || (!nextProps.bypassPropsValue && valueChanged)) {
      const newOptions = options;

      const newSelected = this.getValue(options, nextProps.value);
      this.setState({
        options: newOptions,
        selected: newSelected,
      });
      if (!this.props.multi) {
        this.setState({
          selectedValues: newSelected,
        });
      }
    }
  }

  initializeSelectedValues(value, options) {
    if (!value) return [];
    if (Array.isArray(value)) {
      return value.map((val) => ({ value: val, label: options[val] }));
    }
    return [{ value: value, label: options[value] }];
  }

  getValue(options, value) {
    const theValue = options.filter((option) => {
      if (value instanceof Array) {
        const returnVal = value
          .map((v) => String(v))
          .includes(String(option.value));

        return returnVal;
      } else {
        return option.value == value;
      }
    });

    if (this.props.multi) return theValue;
    return theValue[0];
  }

  getOptions(props) {
    return Object.keys(props.options).map((key) => ({
      value: key,
      label: props.options[key],
    }));
  }
  componentDidMount() {
    const options = this.getOptions(this.props);
    const newOptions = options;
    const newSelected = this.getValue(options, this.props.value);
    this.setState({
      options: newOptions,
      selected: newSelected,
    });
  }
  render() {
    return (
      <Select
        controlShouldRenderValue={this.props.controlShouldRenderValue}
        tabSelectsValue={false}
        menuPlacement={this.props.menuPlacement}
        isDisabled={this.props.disabled}
        defaultValue={""}
        backspaceRemovesValue={false}
        isSearchable={
          !this.props.controlShouldRenderValue ? false : this.props.isSearchable
        }
        className={
          (this.props.isDropDownButton ? "bt-select-button " : "") +
            this.props.className || ""
        }
        //the below bypasses the unique key problems
        onInputChange={this.onInputChange}
        closeMenuOnSelect={true}
        inputValue={this.state.inputValue}
        getOptionValue={({ value }) => {
          return value ?? Math.random();
        }}
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;
          const valueExistsButSelected = this.state.options.some(
            (o) =>
              o?.label?.toLowerCase?.() ==
                this.state?.inputValue?.toLowerCase?.() &&
              this.state?.selected.some(
                (s) =>
                  s.label?.toLowerCase?.() ==
                  this.state?.inputValue?.toLowerCase?.()
              )
          );
          if (this.state?.inputValue && !valueExistsButSelected) {
            this.onChange(
              { value: "new", label: this.state?.inputValue },
              { action: "create-option" }
            );
          } else if (valueExistsButSelected) {
            e.preventDefault();
          }
        }}
        options={(() => {
          // the function below filters out the selected value
          let returnValue = this.state.options;
          if (this.props.hideSelectedOptions === true) {
            returnValue = this.state.options.filter((o) => {
              const selected = !(this.state.selected instanceof Array)
                ? [this.state.selected]
                : this.state.selected;
              return !selected
                .map((k) => k?.value?.toString())
                .includes(o.value.toString());
            });
          }

          // the function below checks if the text that user types exist in previous option
          // and if exists it does nothing if it doesnt exist it adds an option named 'create tag' {inputvalue}
          if (this.props.allowCreate && this.state.inputValue) {
            if (
              !returnValue.some(
                (o) =>
                  o.label?.toLowerCase?.() ==
                  this.state.inputValue.toLowerCase?.()
              )
            ) {
              returnValue = returnValue.filter((o) => o.value !== "new");
              returnValue.unshift({
                value: "new",
                label: `Create tag "${this.state.inputValue}"`,
              });
            }
          }
          return returnValue;
        })()}
        components={this.props.components}
        name={this.props.name}
        onChange={this.onChange}
        value={!this.props.isDropDownButton ? this.state.selected : null}
        placeholder={this.props.placeholder}
        styles={this.styles()}
        isMulti={this.props.multi}
        isClearable={this.props.isClearable}
      />
    );
  }
}

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <span className="icon-close"></span>
    </components.MultiValueRemove>
  );
};

Select2.propTypes = {
  menuPlacement: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  fontWeight: PropTypes.string,
  allowCreate: PropTypes.bool,
  placeholder: PropTypes.any,
  components: PropTypes.object,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  multi: PropTypes.bool,
  placement: PropTypes.string,
  minHeight: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  border: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  name: PropTypes.string,
  backgroundColor: PropTypes.string,
  dropDownSize: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.object,
  isSearchable: PropTypes.bool,
  isDropDownButton: PropTypes.bool,
  bypassPropsValue: PropTypes.bool,
  controlBackground: PropTypes.string,
  selectedBackground: PropTypes.string,
  debounceOnChange: PropTypes.bool,
};

Select2.defaultProps = {
  controlShouldRenderValue: true,
  hideSelectedOptions: false,
  isDropDownButton: false,
  placement: "left",
  fontWeight: "normal",
  bypassPropsValue: false,
  menuPlacement: "auto",
  controlBackground: "white",
  selectedBackground: "white",
  backgroundColor: "#e5e8ea",
  width: "auto",
  color: "#153447",
  hoverColor: "#153447",
  hoverBackgroundColor: "transparent",
  minHeight: 36,
  className: "",
  dropDownSize: 24,
  border: "1px solid #d5d9df",
  components: {
    IndicatorSeparator: () => null,
    MultiValueRemove,
  },
  placeholder: "Select",
  onChange: () => {},
  onSearch: () => {},
  debounceOnChange: false,
  options: {},
  disabled: false,
  multi: false,
  value: "",
  styles: {},
  allowCreate: false,
  isSearchable: true,
  isClearable: true,
};
